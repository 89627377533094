@tailwind base;
@tailwind components;
@tailwind utilities;

@import-normalize;

.card {
    @apply bg-gradient-to-br from-zinc-700 to-zinc-800 rounded-lg w-1/2 lg:w-1/4 text-center content-center px-2 py-4 shadow-lg shadow-indigo-700 border-2 border-indigo-600;
}

.input {
    @apply px-2 py-1.5 bg-zinc-800 rounded-lg focus:ring-0 border-2 border-indigo-700 focus:border-indigo-600 transition shadow shadow-indigo-700 focus:outline-none;
}

.btn {
    @apply  transition px-2.5 py-2 rounded-md shadow;
}

.btn-primary {
    @apply bg-zinc-700 hover:bg-indigo-600 hover:border-zinc-700 border-indigo-600 border-2 shadow-lg;
}

.navItem {
    @apply px-10 py-2 shadow shadow-indigo-500 rounded-xl flex transition cursor-pointer;
}

body {
    @apply bg-gradient-to-br from-zinc-700 to-zinc-800 text-gray-50;
    font-family: "Montserrat", sans-serif;
    height: 100%;
    overflow-y: hidden;
  }
  
  main {
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    align-items: center;
    overflow-y: hidden;
  }
  
  h2 {
    font-weight: 500;
    font-size: 150%;
    letter-spacing: 0;
    font-family: "Montserrat", sans-serif;
  }
  
  h3 {
    text-align: center;
    color: var(--dark);
    font-weight: 700;
    font-size: 2rem;
    letter-spacing: 1px;
    font-family: "Montserrat", sans-serif;
    padding: 2rem 0 1.5rem 0;
    margin: 0;
    text-transform: capitalize;
  }
  
  h5 {
    text-align: center;
    color: var(--dark);
    font-weight: 500;
    font-size: 130%;
    text-align: justify;
    letter-spacing: 0;
    font-family: "Montserrat", sans-serif;
    padding: 0;
    line-height: 125%;
    margin: 0;
  }
  
  kbd {
    background: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 1px 1px #00000028, 0 2px 0 0 #ffffffc3 inset;
    color: #131313;
    display: inline-block;
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
    margin: auto;
  }
  
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000000e1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    z-index: 100;
  }
  
  .modal {
    width: clamp(40%, 600px, 90%);
    height: min(70%, 350px);
  
    margin: auto;
    padding: 0 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @apply bg-zinc-800 border-2 h-auto border-indigo-600 shadow-lg shadow-indigo-700
  }

  .disabled {
    @apply opacity-75;
  }
  
  .pink {
    color: #c273ff;
  }
  
  .gray {
    color: #666666;
  }
  
  .light-blue {
    color: #00b7ff;
  }
  
  .modal-button {
    position: relative;
    bottom: 1.5rem;
    padding: 0 3rem;
    min-height: 3rem;
    margin: auto auto 0 auto;
    background: var(--dark);
    color: var(--light);
  }
  
  .save-button {
    padding: 0 1rem;
    margin: 2rem auto auto 0;
    background: var(--gradient);
    color: var(--dark);
  }
  
  .close-button {
    padding: 0 2rem;
    height: 2.5rem;
    margin: 2rem auto 1rem 0;
    background: #101111;
    /* border: 1px dashed #9a9a9a99; */
    color: #ffaa00;
    border-radius: 4px;
    transition: background ease 400ms;
    box-shadow: 1px 1px 15px #03030399;
  }

  
  ::placeholder {
    font-style: italic;
  }
  
  .container {
    display: flex;
    width: 50vw;
    height: 50%;
    margin: auto;
  }
  
  ul,
  li {
    padding: 0;
    margin: 0;
  }
  
  ul {
    position: fixed;
    bottom: 0.5rem;
    right: 0;
    top: 0.5rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .bottom {
    justify-content: flex-end;
  }
  
  .top {
    justify-content: flex-start;
  }
  
  li {
    width: 225px;
    margin: 0.5rem 1.5rem;
    padding: 0 1rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
  }
  
  .notification-text {
    margin: auto auto auto 0;
    padding: 0;
    font-size: 100%;
    font-weight: 600;
    letter-spacing: 0.25px;
    font-family: "Montserrat", sans-serif;
  }
  
  .add-button {
    padding: 0 1rem;
    margin: 2rem auto auto 0;
    background: var(--gradient2);
    color: var(--dark);
  }
  
  .close {
    height: 1.1rem;
    background: transparent;
    border: none;
    outline: none;
    margin: 0 0 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .close svg {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  
  .sub-header {
    margin: 1rem auto 1rem 0;
    color: #9e9e9e;
  }
  
  @media screen and (min-width: 960px) {
    button {
      cursor: pointer;
    }
    /* .modal {
      width: 750px;
      height: 300px;
    } */
    .input,
    input {
      width: calc(25vw);
    }
  }
